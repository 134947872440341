.details p, .details h5 {
    text-align: center;
}

.details {
    width: 25%;
    align-items: center;
}

.details-img-container {
    width: 18%;
    margin: 0 auto;
}

.details-img-container img {
    width: 100%;
    margin-bottom: 1em;
}

.download-text {
    color: white!important;
    font-family: Rubik-SemiBold, sans-serif;
    text-align: center!important;
}
