.head {
    background-color: #F3F7F9;
    display: flex;
    padding: 8%;
}

.header-left {
    width: 45%;
}

.header-right {
    width: 45%;
    align-items: center;
}

.header-right-inner-container {
    margin: 0 auto;
    width: 50%;
}

.button-formation-container {
    width: 50%;
}

.price-ht {
    font-size: 32px;
    color: #1F1D3A;
    font-family: Rubik-Regular, sans-serif;
}

.price-ttc {
    color: #7A7A7A;
    font-size: 20px;
    margin-left: 4%;
}

.details-formation {
    display: flex;
    justify-content: space-around;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(17, 95, 251, 0.05), 0px 2px 4px rgba(17, 95, 251, 0.1);
    border-radius: 12px;
    width: 80%;
    margin: -5% auto;
    padding-top: 5%;
    padding-bottom: 5%;
}

.contenu-formation {
    display: flex;
    margin-top: 13%;
    justify-content: space-around;
}

.contenu-formation-img-container {
    width: 30%;
}

.download-button {
    display: inline-block;
    padding-left: 15%;
    padding-right: 15%;
    border-radius: 5px;
}
.contenu-formation-text {
    width: 40%;
}

.lorem-flex {
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: space-around;
}

.competences-visees {
    background-color: #292651;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
}

.competence-title {
    color: white;
    text-align: center;
}

.competence-undertitle {
    color: lightgrey;
    text-align: center;
    font-size: 22px;
    margin-bottom: 2% !important;
}

.lorem-container h5 {
    color: white;
}

.lorem-container p {
    color: lightgrey;
}

.lorem-big-container {
    display: flex;
    width: 30%;
    margin-top: 2%;
}

.checkbox {
    font-size: 25px;
    margin-right: 3%;
}

.thematique {
    display: inline-block;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 20px;
}

@media screen and (max-width: 1200px) {
    .header-right {
        display: none;
    }
    .header-left {
        width: 100%;
    }
    .contenu-formation {
        flex-direction: column;
        width: 90%;
        margin: 5% auto;
        justify-content: center;
        align-items: center;
    }
    .contenu-formation-img-container {
        width: unset;

    }
    .contenu-formation-img-container img {
        align-self: center;
        margin: 0 auto;
    }
    .contenu-formation-text {
        align-self: center;
        width: 100%;
        flex-direction: column;
        display: flex;
    }
    .download-button {
        width: 50%;
        align-self: center;
    }
}

@media screen and (max-width: 600px) {
    .details-formation {
        flex-direction: column;
        align-items: center;
        padding-left: 2%;
        padding-right: 2%;
    }
    .details {
        width: 100%!important;
        margin-top: 5%;
    }
    .download-button {
        width: 90%;
        align-self: center;
    }
    .lorem-flex {
        flex-direction: column;
        padding-left: 5%;
        padding-right: 5%;
    }
    .lorem-big-container {
        width: 100%;
    }
    .contenu-formation-img-container img {
        width: 100%;
    }
    .contenu-formation-img-container {
        width: 90%;
    }
    .button-formation-container {
        width: 60%;
    }
}