.list-formations-container {
    background-color: #F3F7F9;
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.subtitle {
    text-align: center;
    color: white;
}

.super-container-formations {
    position: relative
}
.big-container-formations {
    position: relative;
}

.grid-formation {
    position: absolute;
    top: 20%;
    width: 85%!important;
    margin: 0 auto;
    align-self: center;
}

.grid-container {
    margin-bottom: 5%;
}

@media (max-width: 1900px) {
    .grid-formation {
        width: 95%!important;
    }
}

@media (max-width: 1500px) {
    .formation-title-card {
        font-size: 1.5rem
    }
    .gestion-projet {
        width: 60%!important;
    }
    .icon-rounded {
        width: 40px!important;
        height: 40px!important;
        margin-bottom: 10px;
    }
}

@media (max-width: 600px) {
    .css-1xgjpwf-MuiGrid-root {
        margin-top: -150px!important;
    }
}