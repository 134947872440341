.copyright {
    color: white;
    font-size: 16px;
}

.footer-container {
    background-color: #292651;
    display: flex;
    justify-content: space-between;
    padding: 2.5%;
}

.footer-container a {
    text-decoration: none;
}

.inner-footer-container {
    display: flex;
    width: 50%;
    margin-top: 1em;
    justify-content: space-around;
}

.footer-paragraph {
    font-size: 16px;
    color: white;
    text-decoration: none;
    font-family: Rubik-Medium, sans-serif;
}

.linkedin-container {
    margin-top: 1em;
}

.font-weight-bold-custom {
    font-family: Rubik-SemiBold;
}

.cgu-container p {
    text-align: justify;
}