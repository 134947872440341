.purple {
    color: #6200EE;
    text-align: center;
}

.grey {
    color: #525F7F;
    text-align: center;
}

.particulier-purple {
    border: 1px solid #6200EE;
    width: 50%;
}

.particulier-grey {
    border: 1px solid rgba(0, 0, 0, 0.12);
    width: 50%;
}

.particulier {
    border-radius: 4px;
    align-items: center;
    margin-right: 2%;
    cursor: pointer;
    margin-top: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.enterprise {
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
    margin-top: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
}
.phone-container {
    display: flex;
}

.phone-inner-container {
    display: flex;
    width: 25%;
    border-radius: 3.5px;
    border: 1px solid lightgrey;
    align-items: center;
}

.img-phone-container {
    display: flex;
    width: 80%;
    margin: 0 auto;
}

.img-phone-container p{
    margin-left: 10px;
}
.img-particulier-container {
    width: 46px;
    height: 46px;
    margin: 0 auto;
}

.img-particulier-container img {
    width: 100%;
}

.sert {
    color: white;
}

.creer-profile {
    color: white;
}

.text-phone {
    margin-left: 5%;
    width: 70%;
}


.form-container {
    position: relative;
}
