.services-container {
    background-color: #F3F7F9;
}

.services-card-container {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto;
    align-self: center;
    position: relative;
    z-index: 3;
}

.services-card {
    background-color: rgba(255, 255, 255, 0.8);
    width: 30%;
    padding: 3%;
    align-items: center;
    border-radius: 12px;
    border: 5px solid #6633CC;
    position: relative;

}

.expertise-it-detail {
    position: absolute;
    bottom: 5%;
}
.road-image {
    width: 100%;
    margin-top: -30%;
}
.onludation {
    width: 100%;
}
.img-services-card {
    width: 100%;
    height: 100%;
    align-self: center;
}

.services-card-title {
 
    margin-top: 10%;
}

.second-service-title {
    max-width: 90%;
    font-size: 28px;
}

.img-services-card-container {
    width: 100%;
    height: 60%;
    margin: 0 auto;
}
.services-title {
    z-index: 3;
    position: relative;
}
.building-img {
    align-self: center;
    margin-top: -30%;
    z-index: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.formation-detail {
    font-size: 18px;
}

.conseil-gestion {
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
    position: relative;
    justify-content: space-around;
}

.inner-conseil-strat-container {
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
    justify-content: space-around;
}


.conseil-gestion-services-container {
    background-color: #F3F7F9;
}
.description-services-container {
    width: 40%;
}

.conseil-strat-container {
    position: relative;
}
.nuage-service2 {
    left: 50%;
    top: 5%;
    z-index: 3;
}
.nuage-service1 {
    left: 80%;
    top: -5%;
}
.nuage-service3 {
    left: 90%;
    top: 85%;
}
.nuage-service4 {
    left: -20%;
    top: 10%;
    z-index: 1;
}
.description-ballon-container {
    width: 40%;
    text-align: justify;
    margin-bottom: 4%;
}

.description-ballon-container p {
    font-size: 18px;
    font-family: Rubik-Regular, sans-serif;
}

.img-gestion-container {
    width: 40%;
    max-width: 600px;
}

.img-route-container {
    width: 50%;
}
.img-ballon-container {
    width: 40%;
    max-width: 400px;
    position: relative;
    z-index: 2;
}

.img-ballon-container img{
    z-index: 2;
    position: relative;
}
.description-services-container p {
    font-size: 18px;
    text-align: justify;
    font-family: Rubik-Regular, sans-serif;
}

.expertisesIT-img {
    display: none;
}
.description-route-container p {
    font-size: 18px;
    font-family: Rubik-Regular, sans-serif;
}

.services-card-title-mobile {
    display: none;
}
.services-presentation-container {
    height: 100vh
}
.description-route-container {
    margin-top: 4%;
    width: 45%;
    z-index: 8;
    margin-left: 8%;
    margin-bottom: 8%;
}

.img-gestion {
    width: 100%;
}

.css-1xgjpwf-MuiGrid-root>.MuiGrid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}
.check-services-container {
    display: flex;
}

.other-conseil-gestion {
    background-color: #f3f7f9;
}

.font-weight-bold {
    font-weight: bold;
}

.conseil-route-gestion {
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin: 0 auto;
 }

.expertises-it-container {
    align-self: center;
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
    margin-top: 5rem!important;
    margin-bottom: 3rem;
}

.go-detail-services-card {
    position: absolute;
    bottom: 10px;
}
.card-expertise-container {
    box-shadow: 0px 0px 1px rgb(17 95 251 / 5%), 0px 1px 3px rgb(17 95 251 / 10%);
    background-color: white;
    padding: 1%;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    width: 33%;
    width: 418px;
    height: 650px;
    border: 4px solid #26BC90;
    position: relative;
    margin: 0 auto;
}

.card-it-img {
    margin-top: -32%;
    align-self: center;
    height: 150px;
    
}

.card-it-supercontainer {
    background-color: #f3f7f9;
}


@media (max-width: 1700px) {
    .services-card-title {
        max-width: 100%;
    }
    .img-route-container {
        width: 70%;
    }
    .expertises-it-container {
        align-self: center;
        display: flex;
        justify-content: space-around;
        width: 90%;
        margin: 0 auto;
    }
}

@media (max-width: 1530px) {
    .card-expertise-container {
        margin-top: 5%;
    }
}

@media (max-width: 1400px) {
    .services-card-title {
        font-size: 20px;
    }
    .formation-detail {
        font-size: 15px;
    }
    .building-img-container {
        width: 50%;
    }
    .card-expertise-container {
        margin-top: 5%;
    }
}

@media (max-width: 1250px) {
    .conseil-gestion {
        flex-direction: column;
    }
    .img-gestion-container {
        width: 70%;
        margin: 0 auto;
    }
    .description-services-container {
        width: 70%;
        margin: 0% auto;
    }
    .img--ballon-container {
        width: 70%;
        margin: 0 auto;
    }
    .nuage-service4 {
        left: 70%;
        top: 20%;
    }
    .nuage-service3 {
        display: none;
    }
    .nuage-service1 {
        left: 65%
    }
    .description-ballon-container {
        width: 70%;
        margin: 5% auto;
    }
    .conseil-route-gestion {
        display: flex;
        justify-content: space-around;
        width: 80%;
        flex-direction: column-reverse;
        margin: 0 auto;
    }
    .img-route-container {
        width: 70%;
        margin: 0 auto;
    }
    .description-route-container {
        margin: 5% auto;
        width: 95%;
    }
    .services-card-container {
        width: 90%;
    }
    .nuage-service2 {
        display: none;
    }

    .nuage-service4 {
        display: none;
    }
    .card-expertise-container {
        margin-top: 10%;
    }
}

@media (max-width: 1050px) {
    .services-card-container {
        width: 95%;
    }
    .building-img-container {
        width: 60%;
    }
    .nuage-service2 {
        display: none;
    }

    .nuage-service4 {
        display: none;
    }
    .building-img {
        bottom: 10%;
    }
    .road-image {
        display: none;
    }
    .expertisesIT-img {
        display: block;
        width: 100%;
    }
}


@media (max-width: 800px) {
    .building-img {
        bottom: 20%;
    }
}

@media (max-width: 665px) {
    .card-expertise-container {
        margin-top: 10rem;
    }
}

@media (max-width: 600px) {
    .building-img {
        bottom: 0;
    }
    .img-services-card-container{
        display: none;
    }
    .description-route-container {
        margin: 15% auto;
        width: 90%;
    }
    .services-card-title {
        margin-top: 0;
    }
    .nuage-service1 {
        display: none;
    }
    .nuage-service2 {
        display: none;
    }
    .nuage-service3 {
        display: none;
    }
    .nuage-service4 {
        display: none;
    }
    .img-ballon-container {
        display: none;
    }
    .road-image {
        display: none
    }
    .services-card-container {
        flex-direction: column;
    }
    .services-card {
        width: 100%;
        margin-bottom: 5%;
    }
    .services-card-title-mobile {
        display: block;
        margin-bottom: 5%;
    }
    .service-card-title-desktop {
        display: none!important;
    }
    .services-card-title-desktop {
        display: none!important;
    }
    .second-services-title {
        display: none;
    }
    .description-services-container {
        width: 100%;
    }
    .description-ballon-container {
        width: 100%;
    }
    .description-route-container {
        width: 100%;
        padding-left: 5%;
    padding-right: 5%;
    }
    .other-conseil-gestion {
        padding-top: 0!important;
    }
    .conseil-gestion {
        padding-bottom: 0!important;
    }
    .img-gestion-container {
        width: 90%;
    }
}




@media (max-width: 435px) {
    .card-expertise-container {
        width: 90%;
    }
}

@media (max-width: 400px) {
    .card-expertise-container {
        height: 700px
    }
}