.adresse-facturation-container {
    background-color: white;
    border-radius: 12px;
    padding: 1%;
    width: 25%;
    display: flex;
    flex-direction: column;
}

.facturation-title {
    max-width: 50%;
    font-family: "Rubik-Regular";
    font-size: 16px;
}

.facturation-container {
    display: flex;
    justify-content: space-between;
}

.cross-icon {
    color: #7A7A7A;
    margin-top: 5%;
}

.formation-price {
    font-family: Rubik-SemiBold, sans-serif;
    margin-top: 5%;
}

.facturation-card {
    border-bottom: 1px solid #E0E0E0;
    padding-top: 2%;
    padding-bottom: 5%;
}

.apercu {
    font-family: Rubik-Regular, sans-serif;
    text-align: center;
    font-size: 26px;
    margin-bottom: 10%;
}

.adresse-facturation-one {
    width: 60%;
}

.adresse-facturation-text {
    font-family: Rubik-Regular, sans-serif;
    font-size: 26px;
    margin-bottom: 4%;
}

.adresse-input {
    width: 47%;
}

.adresse-inner-container {
    display: flex;
    justify-content: space-between;
}

.adresse-input-full {
    width: 100%;
}

.card-number-container {
    border: 1px solid lightgrey;
}

.modifier-container {
    display: inline-block;
    margin-left: auto;
    padding-left: 2%;
    padding-right: 2%;
}

.facturation-inner-container {
    width: 65%;
}
.modifier-text {
    font-family: Rubik-SemiBold, sans-serif;
    font-size: 16px;
    color: #6200EE;
    margin-left: 2%;

}
.pay-button {
    margin-top: 3%;
    border-radius: 5px;
    padding: 1.5% 2% 1.5% 2%;
    display: inline-block;
    margin-left: auto; 
    float: right;
margin-right: 0;
}

.pay-button-paypal {
    padding: 1.5% 2% 1.5% 2%;
    display: inline-block;
    border-radius: 5px;
    position: absolute;
bottom: 20px;
right: 20px;
}
.confirm-dialog-container {
    margin-left: 30%;
    width: 70%;
    margin-top: 5%;
    justify-content: space-between;
}

.cancel-dialog-button {
    padding: 15px;
    border-radius: 5px;
}

.modifier-icon {
    height: 18px;
    margin-right: 5%;
    margin-top: 2%;
}

.modifier-inner-container {
    margin-top: 5%;
 }

 .new-adresse-input {
    width: 97%;
 }

 @media screen and (max-width: 1100px) {
    .facturation-container {
        flex-direction: column;
    }

    .facturation-inner-container {
        width: 100%;
    }
    .adresse-facturation-container {
        width: 100%;
        margin-top: 5%;
    }
}

@media screen and (max-width: 500px) {
    .adresse-inner-container {
        flex-direction: column;
    }
    .informations {
        padding-bottom: 25%;
    }
    .upload-profile {
        width: 100%;
        margin-bottom: 3rem;
    }
    .adresse-input {
        width: 100%;
        margin-bottom: 3rem!important;
    }
    .adresse-facturation-container {
        padding: 5%;
    }
    .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
        padding: 16px!important;
    }
}