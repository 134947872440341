.buttonContainer {
    background-color: white;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    cursor: pointer;
    border: 2px solid #6200EE;
    align-items: center;
}

.buttonText {
    color: #6200EE;
    font-family: Rubik-SemiBold, sans-serif;
    text-align: center;
}


