.connexion-container {
    display: flex;
    height: 100vh;
}

.left-container {
    width: 50%;
}

.img-connexion-container {
    background-color: #292651;
    width: 50%;
    align-items: center;
    padding: 5%;
}

.img-connexion-inner-container {
    width: 100%;
    margin: 15% auto;
 }

.img-connexion-inner-container img {
    width: 100%;
}
.left-container {
    align-items: center;
}

.form-connexion-container {
    width: 70%;
    margin: 0 auto;
}

.text-field-container {
    width: 100%;
}

.text-field {
    width: 100%;
}

.forgotten-password {
    text-align: right;
    color: #1F1D3A
}

hr {
    width: 46%;
}

.ou-text {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 1%;
}

.connexion-logo {
    margin-top: 10%;
    margin-bottom: 10%;
    width: 256px;
}

.signup-text {
    margin-left: 10%;
    color: #1F1D3A
}

.left-container a {
    border-bottom-color: #1F1D3A;
    text-decoration: underline;
    color: #1F1D3A;
    border-bottom-width: 1px;
}

.signup-container {
    margin-bottom: 5%;
    width: 100%;
    display: flex;
}

.signup-container a {
    width: 40%;
}
.text-field-container {
    margin-bottom: 5%;
}

.ou-container {
    margin-top: 5%;
}

@media screen and (max-width: 1000px) {
    .connexion-container {
        flex-direction: column;
    }
    .left-container {
        width: 100%;
    }
    .img-connexion-container {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .form-connexion-container {
        width: 90%;
    }
}