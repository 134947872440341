.contact-container {
    display: flex;
    width: 80%;
    justify-content: space-around;
}

.margin-relative {
    margin: -16% auto 0;
    padding-bottom: 5%
}
.block-1-contact {
    padding-top: 3%;
}
.prenom-container {
    display: flex;
}

.contact-inner {
    box-shadow: 0px 0px 1px rgba(17, 95, 251, 0.05), 0px 2px 4px rgba(17, 95, 251, 0.1);
    border-radius: 12px;
    background-color: white;
    padding: 5%;
    width: 40%;
    z-index: 2
}

.contact-super-container {
    background-color: rgb(243, 247, 249);

}

.calendly-inline-widget {
    width: 600px!important;
    min-width: 100%!important;
}
.planification {
    height: 50%;
}

.send-message-text {
    font-size: 26px;
    font-family: Rubik-Regular, sans-serif;
}

.text-prenom-container {
    width: 50%;
    padding-right: 2%
}
.text-nom-container {
    width: 50%;
}

.phone-inner-container2 {
    display: flex;
    width: 33%;
    margin-right: 5%;
    border-radius: 3.5px;
    border: 1px solid lightgrey;
    align-items: center;
}

.prenom-container {
    display: flex;
    margin-bottom: 5%;
    justify-content: space-between;
}

@media screen and (max-width: 2200px) {
    .margin-relative {
        margin: -19% auto 0;
    }
}

@media screen and (max-width: 2000px) {
    .margin-relative {
        margin: -22% auto 0;
    }
}

@media screen and (max-width: 1700px) {
    .margin-relative {
        margin: -25% auto 0;
    }
}

@media screen and (max-width: 1400px) {
    .margin-relative {
        margin: -29% auto 0;
    }
}
@media screen and (max-width: 1300px) {
    .contact-container {
        width: 90%;
    }
    .contact-inner {
        width: 45%;
    }
}

@media screen and (max-width: 1000px) {
    .contact-container {
        flex-direction: column;
    }
    .contact-inner {
        width: 100%;
        margin-bottom: 5%;
    }

}

@media screen and (max-width: 600px) {
    .margin-relative {
        margin: -64% auto 0
    }
    

}
