.buttonContainer {
    background-color: #6200EE;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    cursor: pointer;
    align-items: center;
}

.buttonText {
    color: white;
    font-family: Rubik-SemiBold, sans-serif;
    text-align: center;
}
