.add-formation-container {
    width: 85%;
    margin: 0 auto;
    background-color: #E5E5E5;
}

.formation-container {
    width: 98%;
    margin: 0 auto;
}

.header-admin-text {
    color: #171B1E;
    font-size: 22px;
    font-family: Rubik-SemiBold, sans-serif;
}


.add-formation-icon-container {
    width: 800px;
    margin-left: 4% ;
}
.add-image-container {
    width: 270px;
    height: 270px;
    background-color: lightgrey;
}


.add-image-text {
    color:#5C00E7;
    text-align: center;
}
.add-baniere {
    background-color: white;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: none!important;
    padding: 5% !important;
}

.add-image-container {
    border: 1px solid lightgrey;
    padding: 2%;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 10%;
}

.add-image-container-fillout {
    border: 1px solid lightgrey;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 2%;
    height: 270px;
    width: 270px
}

.add-image-container-img {
    cursor: pointer;
    border-radius: 5px;
    text-align: center;

    max-width: 270px;
    max-height: 270px;
}
.icon-preview {
    margin-left: 70%;
    margin-bottom: 2%;
}

.img-preview {
    width: 90%;
}
.add-programme-container {
    border: 1px solid lightgrey;
    width: 95%;
    border-radius: 5px;
    padding: 5%;
    margin-bottom: 5%;
}

.add-icon-container {
    width: 45%;
    border-radius: 5px;
    border: 1px solid lightgrey;
    margin-left: 55%;
    padding: 3%;
    margin-bottom: 4%;
}
.add-image-container p {
    color : #5C00E7;
    text-align: center;
    align-self: center;
}

.text-field-add-formation {
    width: 48%;
    margin-bottom: 4% !important;
}

.text-field-add-duree {
    width: 30%;
    margin-bottom: 4% !important;
}

.code-hexa {
    width: 95%;
}
.title-add-formation {
    margin-bottom: 3% !important;
}


.submit-formation {
    align-self: flex-end;
    width: 35%;
    justify-content: space-between;
}

.users-container {
    background-color: #F4F7F9;
    width: 85%;
    margin: 0 auto;
}

.table-users {
    margin-top: 3%;
}

.three-dots-icons {
    height: 30px;
    width: 30px!important;
    cursor: pointer;
}

.table-tr {
    position: relative;
}

.table-tr td {
    text-align: center;
    align-items: center;
}

.popup-user {
    right: 2%;
    background-color: white!important;
}

.popup-user p {
    cursor: pointer;
}

.popup-user hr {
    margin: 0.2rem 0;
}

.img-card-formation {
    width: 5%;
}

@media (max-width: 1500px) {
    .title-card-formation {
        width: 30%;
    }
}