.pro-sidebar > .pro-sidebar-inner {
    background: #1F1D3A!important;
}

.pro-sidebar-inner {
    background: #1F1D3A!important;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: transparent!important;
}

.pro-sidebar{
    color: white!important;
}

.img-sidebar {
    width: 100%;
}

.img-sidebar-container {
    width: 60%;
    margin: 5% auto;
}

.pro-sidebar > .pro-sidebar-inner {
    min-height: 290vh!important;
}

.navbar-dropdown {
    width: 15% !important;
}

.dropdown-divider {
    width: 100% !important;
}

.qty-container {
    position: relative;
}

.qty-inner-container {
    position: absolute;
    bottom: 65%;
    border-radius: 50%;
    background: #6200EE;
    right: -20%;
    width: 16px;
    height: 16px;
}

.qty-inner-container p {
    color: white;
    text-align: center;
    font-size: 10px;
    font-family: Rubik-Regular, sans-serif;
}
