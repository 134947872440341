.profile-main {
    background-color: #F4F7F9;
    padding: 5%;
}

.header-profile {
    display: flex;
    margin-top: 2%;
}

.profile-title {
    margin-left: 5%;
}

.header-profile-title {
    font-family: Rubik-SemiBold, sans-serif;
    font-size: 16px;
    color: #525F7F;
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 1%;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    width: 20%;
    text-align: center;
}

.confirmPasswordInput {
    width: 48%;
}
.upload-profile {
    width: 47%;
}

.upload-profile-admin {
    width: 48%;
}
.modification-done {
    margin-top: 8%;
}

.header-profile-purple {
    font-family: Rubik-SemiBold, sans-serif;
    font-size: 16px;
    color: #6200EE;
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 1%;
    cursor: pointer;
    border-bottom: 5px solid #6200EE;
    width: 20%;
    text-align: center;
}

.informations {
    background-color: white;
    padding: 5%;
    margin-top: 5%;
}

.informations h5 {
    font-family: Rubik-Regular, sans-serif;
    font-size: 26px;
    margin-bottom: 1em;
}

.profile-infos-container {
    display: flex;
    justify-content: space-between;
}

.phone-container-profile {
    display: flex;
    width: 48%;
    justify-content: space-between;
}
.informations {
    display: flex;
    flex-direction: column;
}
.phone-inner-container-profile {
    display: flex;
    width: 15%;
    height: 55px;
    border-radius: 3.5px;
    border: 1px solid lightgrey;
    align-items: center;
}

.save-infos-profile {
    width: 20%;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
    margin-left: 80%;
}

.delete-user-button {
    width: 10%;
    margin-top: 2%;
    border-radius: 5px;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
}

.adresse-profile-container {
    background-color: white;
    margin-top: 5%;
    border-radius: 10px;
    padding: 3%;
}

.table-formations {
    background-color: white;
    margin-top: 5%;
}

@media screen and (max-width: 1600px) {
    .header-profile-title {
        width: 30%;
    }
    .header-profile-purple {
        width: 30%;
    }
}

@media screen and (max-width: 1400px) {
    .header-profile-title {
        width: 33%;
    }
    .header-profile-purple {
        width: 33%;
    }
}


@media screen and (max-width: 600px) {
    .profile-infos-container {
        flex-direction: column;
    }
    .header-profile-purple {
        width: 33%;
    }
}