.card-formation-container {
    box-shadow: 0px 0px 1px rgba(17, 95, 251, 0.05), 0px 1px 3px rgba(17, 95, 251, 0.1);
    background-color: white;
    padding: 5%;
    border-radius: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gestion-projet {
    border-radius: 10px;
    width: 40%;
    margin-bottom: 5%;
    height: 30px;
}

.gestion-projet p {
    text-align: center;
    margin-top: 5px;
    font-family: Rubik-SemiBold, sans-serif;
    font-size: 14px;
}

.formation-title-card {
    height: 70px
}

.encadre {
    display: inline-block;
    align-self: center;
    position: absolute;
    top: -2%;
    padding-left: 1%;
    padding-right: 1%;
}

.check-container {
    min-height: 400px;
}

.encadre-text {
    color: white
}

.formation-detail {
    font-family: Rubik-SemiBold, sans-serif;
    color: #6633CC;
    margin-right: 1em;
}

.arrow-icon {
    margin-top: 4px;
}

.go-detail {
    cursor: pointer;
    position: absolute;
    bottom: 2%;
}

.forma-icon {
    margin-right: 2%;
}


@media screen and (max-width: 1700px) {
    .check-container {
        min-height: 450px;
    }
}

@media screen and (max-width: 1580px) {
    .check-container {
        min-height: 500px;
    }
}

@media screen and (max-width: 1530px) {
    .check-container {
        min-height: 400px;
    }
}

@media screen and (max-width: 1100px) {
    .check-container {
        min-height: 450px;
    }
}

@media screen and (max-width: 1040px) {
    .check-container {
        min-height: 480px;
    }
}

@media screen and (max-width: 900px) {
    .check-container {
        min-height: 300px;
    }
}

@media screen and (max-width: 600px) {
    .check-container {
        min-height: 400px;
    }
}

@media screen and (max-width: 530px) {
    .check-container {
        min-height: 500px;
    }
}

@media screen and (max-width: 450px) {
    .check-container {
        min-height: 550px;
    }
}

@media screen and (max-width: 400px) {
    .check-container {
        min-height: 600px;
    }
}