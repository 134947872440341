.purple-bar {
    background: #6200EE;
}

.block-1 {
    background: #292651;
    height: 500px;
    align-items: center;
    z-index: 0;
    position: relative;
}

.serpentin {
    width: 50%;
}

.MEng {
    font-family: "Rubik-SemiBold";
    font-weight: italic;
}
.big-logo {
    align-self: center;
    width: 20%;
    margin: 1% auto;
}


.logo-container {
    display: flex;
    flex-direction: column;
    padding-top: 3%;
    justify-content: center;
}

.big-logo-img {
    width: 100%;
}

.solutions-container {
    position: relative
}

.navbar-logo {
    margin-left: 2%;
}

.expertise h3, p {
    position: relative;
}

.nuage5 {
    left: 50%;
    top: 5%
}
.nuage6 {
    left: 80%;
    top: 40%
}
.nuage7 {
    left: 5%;
    top: 30%
}
.block3-mobile {
    display: none;
}
.solutions-title {
    z-index: 10;
    position: relative;
}
.reconversion-container {
    width: 80%;
    margin-bottom: 5%;
    margin-left: 10%;
    align-self: center;
    overflow: hidden;
    border-radius: 200px;
    border: 20px solid #F15A24;
}
.description {
    color: #1F1D3A!important;
    font-weight: 400;
    font-family: Rubik-Regular;
    font-size: 25px;
    line-height: 28px;
    text-align: justify;
}
.img-reconversion-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reconversion-img {
    align-self: "center";
}
.reconversion-text {
    color: white
}
.reconversion-title {
    color: #FF5B20;
    font-size: 24px;
}
.block2-container {
    position: relative;
}
.reconversion-left {
    width: 40%;
    margin-top: 2%;
}
.reconversion-button {
    display: inline-block;
    padding-left: 3%;
    padding-right: 3%;
}
.reconversion-inner {
    display: flex;
    padding: 3%;
    background-color: #292651;
}
.html-container {
    align-items: center;
    background-color: #F9F9F9;
}

.float-area{
    position: absolute;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.nuage1 {
    left: 5%;
    top: 5%;
}

.nuage2 {
    left: 80%;
    bottom: 5%;
}

.nuage3 {
    left: 64%;
    top: 5%;
}

.nuage4 {
    left: 35%;
    bottom: -12%;
}

@keyframes floater {
    0%{transform: translateY(-10%);transition: ease 2s;}
    50%{transform: translateY(10%);transition: ease 2s;}
}

.floating-img{
    transform: translateY(-10%);
    animation: floater 1.5s infinite;
    transition: ease 2s;
}

.description-inner-container {
    margin-bottom: 5%;
    margin-top: 5%;
}

.tablette-vue {
    display: none;
}
.description-container {
    width: 43%;
    margin-top: 8%;
}

.mobile {
    display: none;
}
.blockImg1 {
    width: 65%;
}
.block-2 {
    display: flex;
    max-width: 80%;
    margin: 0 auto;
    padding-top: 5%;
    z-index: 5;

}
.head-title {
    color: white;
    text-align: center;
}

.block-3 {
    display: flex;
    justify-content: space-around;
}

.expertise-container {
    width: 25%;
}

.expertise {
    line-height: 28px;
    margin-top: 5%;
    height: 40%;
    text-align: center;
}

.green-background-img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.green-img {
    width: 100%;
}

.serpentin-mobile {
    display: none;
}
.launcher-container {
    align-self: center;
    display: flex;
    position: absolute;
    bottom: 25%;
    left: 44vw;
    justify-content: center;
}
.fusee {
    margin-bottom: -20%;
    z-index: 3;
}

.unknow-paragraph {
    color: #F9F9F9
}

.expertise h3 {
    font-size: 18px;
}

.saut-ligne {
    margin-top: 10px;
}
.green-background {
    width: 100%;
    background-size: 100%;
    position: relative;
}

.low-green-background {
    width: 100%;
}

.block-4 {
    display: flex;
    position: relative;
}

.nos-valeurs {
    width: 43%;
}
.value-title {
    font-size: 18px;
    margin-bottom: 2%;
    margin-top: 2%;
}

.serpentin img {
    position: absolute;
    top: -23%;
    left: 10%
}
.satisfaction {
    color: #F15A24;
}

.engagement {
    color: #2EE0AC
}

.performance {
    color: #23B0FF;
}

.transparence {
    color: #CB1BFF;
}

.value {
    display: flex;
}

.value-description {
    margin-left: 5%;
}

.img-value {
    height: 50%;
}

.img-undisplay {
    width: 88px;
}

.head-value-title {
    font-family: "Rubik-SemiBold";
    font-size: 2rem;
    margin-bottom: 1rem;
}

@media screen and (max-width: 2230px) {
    .serpentin img {
        top: -18%;
    }
    .wave {
        margin-top: -5px;
    }
}

@media screen and (max-width: 2110px) {
    .serpentin img {
        top: -16%;
    }
}

@media screen and (max-width: 1900px) {
    .serpentin img {
        top: -12%;
    }
}

@media screen and (max-width: 1700px) {
    .logo-container {
        padding-top: 8%;
    }
    .description {
        font-size: 18px;
    }
}

@media screen and (max-width: 1675px) {
    .serpentin img {
        top: -13%;
    }
}

@media screen and (max-width: 1530px) {
    .serpentin img {
        top: -12%;
    }
}

@media screen and (max-width: 1485px) {
    .serpentin img {
        top: -13%;
    }
    .nos-valeurs {
        width: 47%;
    }
}


@media screen and (max-width: 1400px) {
    .serpentin img {
        top: -11%;
    }
}

@media screen and (max-width: 1360px) {
    .serpentin img {
        top: -8%;
    }
}

@media screen and (max-width: 1345px) {
    .serpentin img {
        top: -6%;
    }
}

@media screen and (max-width: 1300px) {
    .block-4 {
        flex-direction: column;
    }
    .serpentin {
        display: none;
    }
    .nos-valeurs {
        width: 80%;
        margin: 0 auto;
    }
 
    .value-head-title-container {
        width: 100%;
        margin-left: 0;
    }
    .head-value-title {
        text-align: center;
    }
    .reconversion-left {
        width: 50%;
    
    }
    .nuage6 {
        display: none;
    }
    .reconversion-container {
        width: 90%;
        margin-left: 5%;
    }
    .block-2 {
        max-width: 90%;
    }
    .description-container {
        width: 50%;
    }
    .blockImg1 {
        width: 50%;
    }
    .fusee {
        margin-bottom: -23%;
    }
}

@media screen and (max-width: 1065px) {
    .nuage2 {
        display: none;
    }
    .nuage1 {
        display: none;
    }
    .value-tbalette-container {
        display: none;
    }
    .big-logo {
        width: 40%;
    }
    .nuage4 {
        display: none;
    }
    .nuage7 {
        display: none;
    }
    .fusee {
        margin-bottom: -25%;
    }
    .reconversion-title {
        font-size: 1.4rem;
    }
    .img-reconversion-container {
        width: 40%;
    }
    .img-reconversion-container img{
        width: 100%;
    }
    .reconversion-left {
        margin-left: 5%;
    }
    .tablette-vue {
        display: block;
        margin-top: 4rem;
        margin-bottom: 4rem
    }

}


@media screen and (max-width: 1000px) {
    .block-2 {
        flex-direction: column;
    }

    .wave {
        margin-top: -12px;
    }
    .blockImg1 {
        width: 100%;
    }
    .description-container {
        width: 100%;
        margin-top: 0;
    }
    .nuage5 {
        display: none;
    }
    .fusee {
        display: none;
    }
    .illu-mobile {
        align-self: center;
    }
    .block-3 {
        display: none;
    }
    .block3-mobile {
        display: flex;
        flex-direction: column;
    }
    .create-account {
        display: inline;
        margin-left: 1rem;
    }
    .big-nav {
        padding-bottom: 10px;
    }
    .home-title {
        font-size: 2rem;
        text-align: center;
    }
    .qty-inner-container {
        left: 1%
    }
}


@media screen and (max-width: 600px) {
    .reconversion-inner {
        flex-direction: column;
        justify-content: center;
        padding-bottom: 15%;
    }
    .img-reconversion-container {
        width: 70%;
        align-self: center;
    }
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
    .reconversion-left {
        width: 90%;
        display: flex;
        margin-left: 0;
        flex-direction: column;
        align-self: center;
    }
    .reconversion-title {
        text-align: center;
        width: 100%;
    }
    .reconversion-button {
        width: 90%;
        align-self: center;
    }
    .nuage3 {
        left: 55%;
    }
    .big-logo {
        width: 60%;
    }
    .qty-inner-container {
        left: 2%
    }
}

@media screen and (max-width: 500px) {
    .nuage3 {
        display: none
    }
    .reconversion-button {
        width: 70%;
    }
    .wave {
        margin-top: -5px;
        height: 50px
    }
    .qty-inner-container {
        left: 3%
    }
}