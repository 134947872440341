.formation-list-card {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    background-color: white;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 2%;
    padding-right: 2%;
}

.icon-rounded {
    width: 50px;
    height: 50px;
}