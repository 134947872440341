.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.css-tbawby-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid lightgrey;
  border-radius: 12px
}

.text-purple {
  color: #6200EE;
}

.float-right {
  float: right
}

.navbar {
  position: relative;
  z-index: 999;
  width: 100%;
  padding-top: 1%;
  background-color: #ccc;
}

.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
}

p {
  font-family: Rubik-Light, serif;
  margin-bottom: 0px!important;
}

.big-nav {
  padding-top: 1%;
  background-color: white!important;
  padding-bottom: 1%;
}

a {
  text-decoration: none!important;
  color:#525F7F!important;
}

.custom-container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.inner-custom-container {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  flex: auto;
}
.footer {
  margin-top: auto;
  flex: none;
}
a:hover {
  color: inherit;
}

.error {
  color: red
}

@font-face {
  font-family: 'Rubik-Light';
  src: local('Rubik-Light'), url(./fonts/Rubik-Light.ttf);
}

@font-face {
  font-family: 'Rubik-Black';
  src: local('Rubik'), url(./fonts/Rubik-Black.ttf);
}

@font-face {
  font-family: 'Rubik-SemiBold';
  src: local('Rubik-SemiBold'), url(./fonts/Rubik-SemiBold.ttf);
}

@font-face {
  font-family: 'Rubik-Medium';
  src: local('Rubik-Medium'), url(./fonts/Rubik-Medium.ttf);
}

@font-face {
  font-family: 'Rubik-Regular';
  src: local('Rubik-Regular'), url(./fonts/Rubik-Regular.ttf);
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row {
  height: auto;
  max-height: 200px!important
}

.css-ece9u5 {
  width: 47%;
}

.cursor-pointer {
  cursor: pointer;
}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
  height: auto;
  max-height: 200px!important
}
.nav-link-self {
  font-family: Rubik-SemiBold, serif!important;
  color: #525F7F!important;
  font-size: 16px;
}

.nav-link-dark {
  color: black!important;
}
.w-30 {
  width: 30%;
}

.create-account {
  background: #6200EE;
  color: white!important;
  margin-right: 5%;
  border-radius: 5px;
}

.w-40 {
  width: 40%;
}

.quantity-picker {
  border: none!important;
  display: flex!important;
  align-items: center!important;
}

.quantity-modifier {
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.quantity-display {
  font-size: 1.2rem!important;
}

.color-dark {
  color: black!important
}
.css-uhb5lp {
  padding: 5%;
  max-width: 1200px!important;
}


* {
  font-family: Rubik-SemiBold, serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 600px) {
  html {
    zoom: .8;
  }
}

@media only screen and (min-width: 800px) {
  html {
    zoom: .9;        
  }
}

@media only screen and (min-width: 1000px) {
  html {
    zoom: 1;
  }
}

