.cart-table {
    width: 50% !important;
    border-collapse: unset!important;
    background-color: white;
    border-radius: 12px;
    padding: 2%;
}

.title-cart-table {
    width: 40%;
}

.qty-cart-table {
    width: 15%;
    margin-right: 5%;
}

.prix-cart-table {
    width: 15%;
}

.supprimer-cart-table {
    width: 15%;
}

.super-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #F9FEFE;
    justify-content: center;
}

.no-cart-title {
    font-family: "Rubik-Regular";
}
.no-cart-button {
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 5%;
}
.inner-no-cart-container {
    align-self: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 20vh;
}

.no-cart-container {
    align-items: center;
    display: flex;
    flex-direction: column;
  
}
.delete-img {
    height: 25px;
    cursor: pointer;
}

.radio-container {
    display: flex;
    flex-direction: column;
}

.price-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
}

.appliquer-button {
    border-radius: 10px;
    align-items: center;
    text-align: center;
    width: 35%;
}

.appliquer-button p{
    text-align: center;
    margin-top: 5%;
}
.paiement-container {
    background-color: white;
    border-radius: 12px;
    padding: 2%;
    margin-left: 5%;    
    width: auto;
}

.cart-mobile {
    display: none;
}


.cart-container {
    width: 60%;
}
.mode-paiement-text {
    font-family: Rubik-Regular, sans-serif;
    font-size: 26px;
}

.total-panier-text {
    font-family: Rubik-Regular, sans-serif;
    font-size: 20px;
}

.price-text {
    font-family: Rubik-SemiBold, sans-serif;
}

.valider-commande {
    border-radius: 5px;
    margin-top: 5%;
    padding: 8%;
}

.aimer-aussi {
    font-family: Rubik-Regular, sans-serif;
    margin-top: 5%;
    margin-bottom: 3%;
}

.cart-item-container {
    border-top: 1px solid rgba(82, 95, 127, 0.12);
    padding: 2%;
}

@media screen and (max-width: 1000px) {
   .cart-desktop {
    display: none;
   }
   .cart-mobile {
    display: block;
   }
}

@media screen and (max-width: 900px) {
    .cart-super-container {
        flex-direction: column;
    }
    .paiement-container {
        margin-left: 0;
        margin-top: 5%;
    }
    .cart-container {
        width: 100%;
    }
    .separator {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .paiement-container {
        padding: 10%;
    }
    .cart-desktop {
        display: none;
    }
    .cart-mobile-card {
        margin-top: 10%;
    }
    .qty-mobile {
        margin-top: 0.8rem;
    }
    .formation-price {
        margin-top: 3%!important;
    }
    .input-mobile {
        width: 100%;
        margin-bottom: 3rem;
    }
    .card-mobile-text {
        font-size: 0.8rem;
    }
    .aimer-aussi {
        margin-bottom: 150px;
    }
}